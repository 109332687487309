/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { sharedRef } from '@vue-storefront/core';
import { useRoute, computed } from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';

const useTranslation = (translationsComposableKey = 'translations') => {
  const translations = sharedRef({}, translationsComposableKey);
  const trans = useI18n();

  // Get the current route path and extract the locale from it
  const {
    value: { path },
  } = useRoute();
  const currentLocale = computed(() => path.split('/')[1] || 'it');

  // Define a function called $gt that returns the translation for a given key
  // If no locale is provided, the current locale parsed from the current path is used
  const $gt = (stringToTranslate: string) => translations?.value?.[stringToTranslate] || trans.t(stringToTranslate);

  return {
    translations,
    $gt,
    currentLocale,
  };
};

// Export the useTranslation function as the default export of this module
export default useTranslation;
